import React from 'react'
import withRouter from 'src/lib/with_router'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import en from 'date-fns/locale/en-US'
import it from 'date-fns/locale/it'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import axios from 'src/lib/axios'
import { Logo } from './system_wide/logo'
import ThemeContext from 'src/components/system_wide/theme_context'
import MoreText from './more_text'
import { Button, Form } from 'react-bootstrap'
import Loading from './system_wide/loading'

const { t } = i18next

class DigitalPassport extends React.Component {
  static contextType = ThemeContext
  state = { ready: false, data: {} }

  locale = null

  constructor(props) {
    super(props)

    switch (i18next.language) {
      case 'it':
        this.locale = it
        break
      case 'en':
        this.locale = en
        break
      case 'fr':
        this.locale = fr
        break
      case 'de':
        this.locale = de
        break
      default:
        this.locale = it
    }
  }

  async componentDidMount() {
    let { params } = this.props.router
    await this.retriveData(params.rfid)
  }
  retriveData = async rfid => {
    try {
      let { data } = await axios(`api/search/${rfid}`)
      let { Tessiture, Tagli, Spedizioni, ProdottoFinito, Lavorazioni } = data.data
      let { current_language } = this.context
      current_language = current_language.toUpperCase()

      let product_name = ProdottoFinito[`DescrizioneArticolo_${current_language}`] ?? ProdottoFinito.DescrizioneArticolo
      let product_code = ProdottoFinito.CodiceArticolo?.trim() ?? ''
      let product_color_code = ProdottoFinito.CodiceColore
      let product_color = ProdottoFinito[`DescrizioneColore_${current_language}`] ?? ProdottoFinito.DescrizioneColore
      let product_size = ProdottoFinito.TgDm
      let product_composition = ProdottoFinito[`Composizione_${current_language}`] ?? ProdottoFinito.Composizione
      let product_washing_instructions =
        ProdottoFinito[`IstruzioniLavaggio_${current_language}`] ?? ProdottoFinito.IstruzioniLavaggio

      let design = {
        members: [
          { MatricolaOperatore: '2695', Operatore: 'ALEXA' },
          { MatricolaOperatore: '1111', Operatore: 'SUSANNA' },
          { MatricolaOperatore: '3664', Operatore: 'VERONICA' },
          { MatricolaOperatore: '2222', Operatore: 'LINA' },
        ],
      }
      let knittings = Tessiture.map(t => {
        t.DescrizioneArticolo = t[`DescrizioneArticolo_${current_language}`]
          ? t[`DescrizioneArticolo_${current_language}`]
          : t.DescrizioneArticolo
        return t
      })
      let cuts = Tagli[0] ?? null

      if (cuts) {
        let members = Lavorazioni.filter(l => l.SiglaReparto === 'TAG' && l.MatricolaOperatore).map(l => ({
          MatricolaOperatore: l.MatricolaOperatore,
          Operatore: l.Operatore,
        }))
        members = new Map(members.map(m => [m.MatricolaOperatore, m]))
        members = [
          { MatricolaOperatore: '5648', Operatore: 'FABRIZIO' },
          { MatricolaOperatore: '3677', Operatore: 'VALENTINA' },
          ...members.values(),
        ]
        if (members.length > 4) {
          members = members.slice(0, 4)
        }
        cuts = { ...cuts, members }
      }

      let fabric_finishing = { members: [{ MatricolaOperatore: '5519', Operatore: 'MASSIMO' }] }

      let sewing = { members: [{}] }
      let sewing_members = Lavorazioni.filter(l => l.SiglaReparto === 'CON' && l.MatricolaOperatore).map(l => ({
        MatricolaOperatore: l.MatricolaOperatore,
        Operatore: l.Operatore,
      }))
      sewing_members = new Map(sewing_members.map(m => [m.MatricolaOperatore, m]))
      sewing_members = [...sewing_members.values()]
      if (sewing_members.length > 4) {
        sewing_members = sewing_members.slice(0, 4)
      }
      sewing = { members: sewing_members }

      let quality_control = {}
      let qc_members = Lavorazioni.filter(l => l.SiglaReparto === 'FIN' && l.MatricolaOperatore).map(l => ({
        MatricolaOperatore: l.MatricolaOperatore,
        Operatore: l.Operatore,
      }))
      qc_members = new Map(qc_members.map(m => [m.MatricolaOperatore, m]))
      qc_members = [...qc_members.values()]
      if (qc_members.length > 4) {
        qc_members = qc_members.slice(0, 4)
      }
      quality_control = { members: qc_members }
      let shipments = {
        ...(Spedizioni?.[0] ?? {}),
        members: [
          {
            MatricolaOperatore: '5444',
            Operatore: 'FRANCO',
          },
          {
            MatricolaOperatore: '5523',
            Operatore: 'NICOLETTA',
          },
          {
            MatricolaOperatore: '5689',
            Operatore: 'ANA',
          },
        ],
      }

      this.setState({
        product_name,
        product_code,
        product_color_code,
        product_color,
        product_size,
        product_composition,
        product_washing_instructions,
        design,
        knittings,
        fabric_finishing,
        cuts,
        sewing,
        quality_control,
        shipments,
        ready: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  renderMember = member => {
    if (!member.MatricolaOperatore) return null
    return (
      <div key={`cuts-${member.MatricolaOperatore}`} className="d-flex flex-column p-2 worked-img">
        <object
          data={`https://www.nicelive.it/wp-content/uploads/2024/07/${member?.MatricolaOperatore}.jpg`}
          type="image/jpeg"
          className="img-fluid"
          style={{ borderRadius: '5%' }}>
          <img alt="" src="/placeholder-image.jpg" className="img-fluid" style={{ borderRadius: '5%' }} />
        </object>

        <span className="mt-4" style={{ fontSize: '1rem' }}>
          {member.Operatore}
        </span>
      </div>
    )
  }

  renderProductNotFound = () => {
    return (
      <div className="text-center d-flex flex-column h-100">
        <div className="bg-light my-auto" style={{ height: '23rem' }}>
          <Logo className="text-center logo mx-auto my-0 pt-5" />
          <div className="mt-4">
            <strong style={{ fontSize: '1.5rem' }}>
              <Trans i18nKey="welcome.rfid_not_found">Code not found</Trans>
            </strong>
          </div>
        </div>
      </div>
    )
  }
  render() {
    let {
      ready,
      product_name,
      product_code,
      product_color_code,
      product_color,
      product_size,
      product_composition,
      product_washing_instructions,
      design,
      knittings,
      fabric_finishing,
      cuts,
      sewing,
      quality_control,
      shipments,
    } = this.state

    if (!ready) return <Loading />
    if (!product_code && !product_name) {
      this.renderProductNotFound()
    }

    let has_internal_knitting = knittings.some(w => w.TessituraEsterna === false)
    let internal_knitting = knittings?.filter(w => w.TessituraEsterna === false)?.[0] ?? null
    let external_knitting = knittings?.filter(w => w.TessituraEsterna === true)?.[0] ?? null
    let knitting_members = []
    if (internal_knitting) {
      knitting_members = [
        {
          MatricolaOperatore: '3333',
          Operatore: 'ANDREA',
        },
        ...(internal_knitting.MatricolaOperatoreInizioLavoro !== '3333'
          ? [
              {
                MatricolaOperatore: internal_knitting.MatricolaOperatoreInizioLavoro,
                Operatore: internal_knitting.OperatoreInizioLavoro,
              },
            ]
          : []),
        ...(internal_knitting.MatricolaOperatoreFineLavoro !== '3333' &&
        internal_knitting.MatricolaOperatoreFineLavoro !== internal_knitting.MatricolaOperatoreInizioLavoro
          ? [
              {
                MatricolaOperatore: internal_knitting.MatricolaOperatoreFineLavoro,
                Operatore: internal_knitting.OperatoreFineLavoro,
              },
            ]
          : []),
      ]
    }

    internal_knitting = {
      ...internal_knitting,
      members: knitting_members,
    }

    return (
      <div className="text-center">
        <div className="bg-light" style={{ height: '23rem' }}>
          <Logo className="text-center logo mx-auto my-0 pt-5" />
        </div>
        <div style={{ backgroundColor: '#f9f5f1' }} className="container">
          <div className="d-flex product-img-container mx-auto flex-column" style={{ top: '-6rem' }}>
            <object
              data={`https://www.nicelive.it/wp-content/uploads/2024/07/${product_code}_${product_color_code}.jpg`}
              type="image/jpeg"
              className="img-fluid"
              style={{ borderRadius: '5%' }}>
              <img alt={product_name} src="/image-not-found.jpg" className="img-fluid" style={{ borderRadius: '5%' }} />
            </object>
            <div className="mb-4 mt-4">{product_name}</div>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <Trans i1vertical-8nKey="digital_passport.product_code">Product code</Trans>
                  </td>
                  <td>{product_code}</td>
                </tr>
                <tr></tr>
                <tr>
                  <td>
                    <Trans i18nKey="digital_passport.product_color">Color</Trans>
                  </td>
                  <td>
                    {product_color_code} {product_color}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Trans i18nKey="digital_passport.product_size">Size</Trans>
                  </td>
                  <td>{product_size}</td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <Trans i18nKey="digital_passport.product_composition">Composition</Trans>
                  </td>
                  <td>{product_composition}</td>
                </tr>
                {product_washing_instructions && (
                  <tr>
                    <td className="align-middle">
                      <Trans i18nKey="digital_passport.product_washing_instructions">Washing instructions</Trans>
                    </td>
                    <td>{product_washing_instructions}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex flex-column">
              <span className="text-description mb-3">
                <Trans i18nKey="digital_passport.initial_description">Find out how we made it.</Trans>
              </span>
              <i className="fa fa-arrow-down-long" style={{ fontSize: '2rem', color: '#9d9994' }}></i>
            </div>
          </div>
          <div className="text-start start-timeline text-uppercase">
            <span>
              <Trans i18nKey="digital_passport.start_timeline">Start</Trans>
            </span>
          </div>
          <div className="timeline text-start">
            <div className="position-relative section">
              <div className="d-flex align-items-center">
                <div className="dot"></div>
                <div className="section-title">
                  <Trans i18nKey="digital_passport.design">Design</Trans>
                </div>
              </div>
              <div className="location mb-3">
                <i className="fa-solid fa-location-dot"></i> <span>MAGLIFICIO PO</span>
              </div>
              <div className="text-center">
                <img
                  alt={t('digital_passport.design', 'Design')}
                  src="/design.jpg"
                  className="img-fluid mb-4"
                  style={{ borderRadius: '5%' }}
                />
                <div className="section-description">
                  <MoreText
                    line_truncate={3}
                    text={
                      <Trans i18nKey="digital_passport.design_description">
                        The unmistakable style of Oscalito ranges from the use of lace, embroidery, macramé (Iconic
                        line) to the more minimal and sober tastes of the Terra and Timeless lines and is curated by a
                        varied team of people who, observing the criteria of ecodesign, design garments that last, with
                        a timeless aesthetic.
                      </Trans>
                    }
                  />
                </div>
                <div className="text-center who-worked mt-3">
                  <Trans i18nKey="digital_passport.who_worked_design">Who worked on the design</Trans>
                  <div className="d-flex flex-row flex-wrap">
                    {design.members.map(m => {
                      return this.renderMember(m)
                    })}
                  </div>
                </div>
              </div>
            </div>
            {knittings.length > 0 && (
              <>
                <div className="position-relative section">
                  <div className="d-flex align-items-center">
                    <div className="dot"></div>
                    <div className="section-title">
                      <Trans i18nKey="digital_passport.knitting">Knitting</Trans>
                    </div>
                  </div>
                  <div className="location mb-3">
                    <i className="fa-solid fa-location-dot me-1"></i>
                    {has_internal_knitting === true && <span>MAGLIFICIO PO</span>}
                    {has_internal_knitting === false && (
                      <span>
                        {external_knitting && external_knitting.FornitoreCitta && external_knitting.FornitoreCitta}
                      </span>
                    )}
                  </div>
                  <div className="text-center">
                    <img
                      alt={t('digital_passport.knitting', 'Knitting')}
                      src="/tessitura.jpg"
                      className="img-fluid mb-4"
                      style={{ borderRadius: '5%' }}
                    />
                    <div className="section-description">
                      <MoreText
                        line_truncate={3}
                        text={
                          <Trans i18nKey="digital_passport.knitting_description">
                            Knitting is the heart of the company, where our precious fabrics are made starting from the
                            yarn. We use tubolar machines of ancient tradition that intentionally produce slowly,
                            enhancing the characteristics of the natural yarn and giving the fabric its characteristics
                            of softness and elasticity.
                          </Trans>
                        }
                      />
                      {has_internal_knitting === true && (
                        <>
                          <table className="table table-bordered mt-3 mb-5">
                            <tbody>
                              <tr className="text-center">
                                <td className="align-middle">
                                  <Trans i18nKey="digital_passport.disposition">Disposition</Trans>
                                </td>
                                <td></td>
                              </tr>
                              <tr className="text-center">
                                <td className="align-middle">
                                  <Trans i18nKey="digital_passport.yarn_description">Yarn description</Trans>
                                </td>
                                <td>{internal_knitting.Filati}</td>
                              </tr>
                              <tr className="text-center">
                                <td className="align-middle">
                                  <Trans i18nKey="digital_passport.fabric_batch">Fabric batch</Trans>
                                </td>
                                <td>{internal_knitting.NumeroPartita}</td>
                              </tr>
                            </tbody>
                          </table>

                          <div className="text-center who-worked">
                            <Trans i18nKey="digital_passport.who_worked_knitting">Who worked on the knitting</Trans>
                            <div className="d-flex flex-row flex-wrap">
                              {internal_knitting.members.map(m => {
                                return this.renderMember(m)
                              })}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="position-relative section">
                  <div className="d-flex align-items-center">
                    <div className="dot"></div>
                    <div className="section-title">
                      <Trans i18nKey="digital_passport.fabric_finishing">Fabric finishing</Trans>
                    </div>
                  </div>
                  <div className="location mb-3">
                    <i className="fa-solid fa-location-dot me-1"></i>
                    {has_internal_knitting === true && <span>MAGLIFICIO PO</span>}
                    {has_internal_knitting === false && (
                      <span>
                        {external_knitting && external_knitting.FornitoreCitta && external_knitting.FornitoreCitta}
                      </span>
                    )}
                  </div>
                  <div className="text-center">
                    <img
                      alt={t('digital_passport.fabric_finishing', 'Fabric finishing')}
                      src="/image-not-found.jpg"
                      className="img-fluid mb-4"
                      style={{ borderRadius: '5%' }}
                    />
                    <div className="section-description">
                      <MoreText
                        line_truncate={3}
                        text={
                          <Trans i18nKey="digital_passport.fabric_finishing_description">
                            A fabric finishing with moderate steaming and drying stabilizes the piece and completes the
                            knitting phase.
                          </Trans>
                        }
                      />
                    </div>
                  </div>
                  {has_internal_knitting === true && (
                    <div className="text-center who-worked mt-3">
                      <Trans i18nKey="digital_passport.who_worked_fabric_finishing">
                        Who worked on the fabric finishing
                      </Trans>
                      <div className="d-flex flex-row flex-wrap">
                        {fabric_finishing.members.map(m => {
                          return this.renderMember(m)
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {cuts !== null && (
              <div className="position-relative section">
                <div className="d-flex align-items-center">
                  <div className="dot"></div>
                  <div className="section-title">
                    <Trans i18nKey="digital_passport.cutting">Cutting</Trans>
                  </div>
                </div>
                <div className="location mb-3">
                  <i className="fa-solid fa-location-dot"></i> <span>MAGLIFICIO PO</span>
                </div>
                <div className="text-center">
                  <img
                    alt={t('digital_passport.cutting', 'Cutting')}
                    src="/taglio.jpg"
                    className="img-fluid mb-4"
                    style={{ borderRadius: '5%' }}
                  />
                  <div className="section-description">
                    <MoreText
                      line_truncate={3}
                      text={
                        <Trans i18nKey="digital_passport.cutting_description">
                          The fabric are cut using two different methods. The automatic computer-assisted cut cuts
                          jersey, flat knit fabrics and woven fabrics. For the tubular ribbed fabric, the cut is done by
                          hand, guiding the fabric under the saw: a delicate, accurate and highly precise job. The same
                          manual cut is done for lace, embroidery and macramé. The pieces to be assembled by model, size
                          and colour are placed in the boxes that make up the individual production lots.
                        </Trans>
                      }
                    />

                    <table className="table table-bordered mt-3 mb-5">
                      <tbody>
                        <tr className="text-center">
                          <td>
                            <Trans i18nKey="digital_passport.disposition">Disposition</Trans>
                          </td>
                          <td>{`${cuts.ID_DispCassa} ${t('digital_passport.of', 'of')} ${cuts.AnnoDisposizione}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="text-center who-worked mt-3">
                  <Trans i18nKey="digital_passport.who_worked_cutting">Who worked on the cutting</Trans>
                  <div className="d-flex flex-row flex-wrap">
                    {cuts.members.map(m => {
                      return this.renderMember(m)
                    })}
                  </div>
                </div>
              </div>
            )}
            <div className="position-relative section">
              <div className="d-flex align-items-center">
                <div className="dot"></div>
                <div className="section-title">
                  <Trans i18nKey="digital_passport.sewing">Sewing</Trans>
                </div>
              </div>
              <div className="location mb-3">
                <i className="fa-solid fa-location-dot"></i> <span>MAGLIFICIO PO</span>
              </div>
              <div className="text-center">
                <img
                  alt={t('digital_passport.sewing', 'Sewing')}
                  src="/confezionamento.jpg"
                  className="img-fluid mb-4"
                  style={{ borderRadius: '5%' }}
                />
                <div className="section-description">
                  <MoreText
                    line_truncate={3}
                    text={
                      <Trans i18nKey="digital_passport.sewing_description">
                        The sewing department is fed by a sliding belt machine that sorts the batches to be assembled
                        according to each individual operation and sends them to the workstations according to some
                        parameters: machine setting, type of sewing, batch color, operator ability, urgency.
                      </Trans>
                    }
                  />
                </div>
              </div>
              <div className="text-center who-worked mt-3">
                <Trans i18nKey="digital_passport.who_worked_sewing">Who worked on the sewing</Trans>
                <div className="d-flex flex-row flex-wrap">
                  {sewing.members.map(m => {
                    return this.renderMember(m)
                  })}
                </div>
              </div>
            </div>
            <div className="position-relative section">
              <div className="d-flex align-items-center">
                <div className="dot"></div>
                <div className="section-title">
                  <Trans i18nKey="digital_passport.quality_control">Quality control</Trans>
                </div>
              </div>
              <div className="location mb-3">
                <i className="fa-solid fa-location-dot"></i> <span>MAGLIFICIO PO</span>
              </div>
              <div className="text-center">
                <img
                  alt={t('digital_passport.quality_control', 'Quality control')}
                  src="/controllo-qualità.jpg"
                  className="img-fluid mb-4"
                  style={{ borderRadius: '5%' }}
                />
                <div className="section-description">
                  <MoreText
                    line_truncate={3}
                    text={
                      <Trans i18nKey="digital_passport.quality_control_description">
                        Each finished product undergoes quality control to verify its measurements, aesthetic
                        conformity, regularity of the fabric, accessories and stitching. The garments are then ironed,
                        labelled and bagged.
                      </Trans>
                    }
                  />
                </div>
              </div>
              <div className="text-center who-worked mt-3">
                <Trans i18nKey="digital_passport.who_worked_quality control">Who worked on the quality control</Trans>
                <div className="d-flex flex-row flex-wrap">
                  {quality_control.members.map(m => {
                    return this.renderMember(m)
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative last-section">
            <div>
              <div className="d-flex align-items-center">
                <div className="dot"></div>
                <div className="section-title">
                  <Trans i18nKey="digital_passport.logistics">Logistics</Trans>
                </div>
              </div>
              <div className="location mb-3">
                <i className="fa-solid fa-location-dot"></i> <span>MAGLIFICIO PO</span>
              </div>
              <div className="text-center">
                <img
                  alt={t('digital_passport.logistics', 'Logistics')}
                  src="/image-not-found.jpg"
                  className="img-fluid mb-4"
                  style={{ borderRadius: '5%' }}
                />
                <div className="section-description">
                  <MoreText
                    line_truncate={3}
                    text={
                      <Trans i18nKey="digital_passport.logistics_description">
                        The RFID label associated with each item, in addition to ensuring the traceability of the supply
                        chain, allows for the elimination of any errors in loading the warehouse and subsequent shipping
                        to the customer.
                      </Trans>
                    }
                  />
                  {shipments.NumeroDdt && (
                    <table className="table table-bordered mt-3 mb-5">
                      <tbody>
                        <tr className="text-center">
                          <td>
                            <Trans i18nKey="digital_passport.bill_number">Bill number</Trans>
                          </td>
                          <td>{shipments.NumeroDdt}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  <div className="text-center who-worked">
                    <Trans i18nKey="digital_passport.who_worked_logistics">Who worked on the logistics</Trans>
                    <div className="d-flex flex-row flex-wrap">
                      {shipments.members.map(m => {
                        return this.renderMember(m)
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="certification-section d-flex flex-column">
            {/* <span className="text-description mb-3">
              <Trans i18nKey="digital_passport.certification_description">This garment is certified.</Trans>
            </span>
            <div className="certification">Certificazione 1</div>
            <div className="certification">Certificazione 2</div> */}
          </div>
        </div>
        <div className="visit-shop-section d-flex flex-column">
          <b className="visit-shop-description">
            <Trans i18nKey="digital_passport.visit_shop_description">
              Discover our quality and passion for craftsmanship
            </Trans>
          </b>
          <div className="visit-shop-button text-uppercase align-self-center">
            <Trans i18nKey="digital_passport.visit_shop_button">Visit our shop</Trans>
          </div>
        </div>
        <div className="newsletter d-flex flex-column align-items-center">
          <b className="newsletter-title text-uppercasse">
            <Trans i18nKey="digital_passport.newsletter_title">Subscribe to newsletter</Trans>
          </b>
          <div className="newsletter-description">
            <Trans i18nKey="digital_passport.newsletter_description">
              Immerse yourself in Oscalito's green heart, sign up for insider updates, behind-the-scenes stories and
              special offers.
            </Trans>
          </div>

          <Form className="position-relative" style={{ width: '25rem' }}>
            <Form.Control type="email" placeholder={t('digital_passport.insert_email', 'Insert you email')} />
            <Button>
              <Trans i18nKey="digital_passport.newsletter_send_button">Send</Trans>
            </Button>
          </Form>
        </div>
      </div>
    )
  }
}

export default withRouter(DigitalPassport)
